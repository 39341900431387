<template>
  <div class="position-relative col-12 col-lg-4">
    <div v-if="trait" :class="'trait-rouge ' + traitColor"></div>
    <div v-if="trait" :class="'trait-rouge ' + traitColor"></div>
    <div class="circle" :class="'m-auto'">
      <img :src="circle" alt="" />
    </div>
    <h6 :class=" titleClass + ' title-etape mt-4 text-danger'" v-html="title"></h6>
    <p :class="contentClass" v-html="content"></p>
  </div>
</template>

<script>
export default {
  props: {
    trait: Boolean,
    traitColor: String,
    circle: String,
    title: String,
    content: String,
    titleClass: String,
    contentClass: String
  }
}
</script>
<style scoped lang="scss">
.circle{
  width: 80px;
  height: 80px;
}
</style>
