<template>
  <div class="bg-secondary" :class="{'bg-dark' : dark}">
      <div class="container text-center py-4">
        <TitleTextButton
          :other="true"
          :title="true"
          :text="true"
          otherContent="<p class='subtitle mt-4'>Le rôle d'une cellule d'innovation ouverte</p>"
          :titleContent="componentTitle"
          :textContent="componentContent"
          :otherClass="dark ? 'text-white' : 'text-dark'"
          :titleClass="dark ? 'text-white' : 'text-dark'"
          :textClass="dark ? 'text-white' : 'text-dark'"
        />
        <div class="etapes d-flex flex-column flex-lg-row align-items-start mt-5">
          <EtapesContainer v-for="etape in etapes" :key="etape.id"
            :circle="etape.circle"
            :title="etape.title"
            :content="etape.content"
            :titleClass="dark ? 'text-white' : 'text-dark'"
            :contentClass="'px-3 my-4 ' + (dark ? 'text-white' : 'text-dark')"
          />
        </div>
      </div>
    </div>
</template>

<script>
import TitleTextButton from './TitleTextButton'
import EtapesContainer from './EtapesContainer'

export default {
  props: {
    dark: Boolean,
    pClass: String,
    componentTitle: String,
    componentContent: String,
    etapes: Array
  },
  components: {
    TitleTextButton,
    EtapesContainer
  },
  data () {
    return {
      textWhite: 'text-white'
    }
  }
}
</script>
