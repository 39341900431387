/* eslint-disable no-undef */
<template>
  <div class="open-innovation">
    <HeaderOrder
      title="Open innovation / Innovation ouverte"
      text="AZ Initiatys conseille et accompagne les grandes organisations dans la création de leur cellule d’innovation ouverte ou la professionnalisation de leur démarche d'open innovation."
    />
    <Etapes
        componentTitle="Identifier, analyser, suivre les tendances et les innovations des start-ups"
        componentContent="Une cellule d'open innovation opère la veille des acteurs économiques émergeants du secteur de l'entreprise afin d'accélérer l'adoption de leurs innovations dans les processus de l'entreprise."
        traitColor="bg-dark"
        :etapes="etapes"
    />

    <div :class="'container mt-5 mt-lg-0 my-lg-4 p-lg-0'">
        <img class="d-block d-lg-none" src="../assets/img/svg/incub-mobil.svg" alt="" />
        <img class="d-none d-lg-block" src="../assets/img/svg/incub-desktop.svg" alt="" />
    </div>

    <div class="container d-flex flex-column flex-lg-row">
      <div :class="'col-lg-5 py-5 px-0 p-lg-0 mr-lg-5'">
        <TitleTextButton
          :title="true"
          :text="true"
          :other="true"
          titleContent="AZ Initiatys, trait d'union entre start-ups et grandes organisations"
          textContent="<p>Depuis plus de 20 ans, AZ Initiatys est le trait d'union entre deux univers qui rencontrent de nombreuses difficultés pour se comprendre et collaborer : celui des entrepreneurs et celui des grandes organisations.</p><p>Co-auteur du rapport Léonard sur l'innovation ouverte dans l'écosystème de sécurité et de défense, Hervé Dechene a recensé les meilleures pratiques mondiales en matière d'alliances entre start-ups et grandes organisations.</p>"
          otherContent='<p class="subtitle">Notre métier</p>'
          otherClass="text-dark"
        />
      </div>
      <img class="d-block d-lg-none" src="../assets/img/img3.png" alt="" />
      <div class="d-none d-lg-block col-lg-8 p-0 ml-5">
        <div class="row my-2" style="height:178px; overflow:hidden;">
          <div class="col-lg-6 p-0 img">
            <img src="../assets/img/img7x2.png" alt="" />
          </div>
          <div class="col-lg-6 pl-3 pr-3">
            <div class="h-100 w-100 bg-danger"></div>
          </div>
        </div>
        <div class="row column mb-4" style="height:178px; overflow:hidden;">
          <div class="col-lg-4 pl-0 pr-0 m-2">
            <div class="h-100 w-50 ml-auto bg-danger"></div>
          </div>
          <div class="col-lg-6 img pl-0 m-2">
            <img src="../assets/img/campaign2x.png" alt="" />
          </div>
          <div class="col-lg-2 p-0">
            <div class="h-100 w-100 bg-danger"></div>
          </div>
         </div>
      </div>
    </div>

    <div class="container">
      <div class="row flex-column flex-lg-row m-0">
        <div :class="'order-0 order-lg-1 pt-5 px-0 pt-lg-0 col-lg-6'">
          <p>Il est à l'origine de la création de 2 cellules d'innovation ouverte mutualisées sur des filières. Il intervient en appui aux directions générales et en transfert de compétence auprès des responsables d'innovation ouverte pour définir la stratégie, la démarche, les compétences et les outils les plus adaptés aux objectifs à atteindre.</p>
          <p>Sollicité lors des négociations entre les fondateurs de jeunes entreprises innovantes et les dirigeants des grandes organisations, il veille à la bonne compréhension des stratégies, enjeux, missions et objectifs de chaque partie.</p>"
        </div>

        <img class="d-block d-lg-none" />
        <div class="d-none d-lg-block order-lg-0 col-lg-6 row pl-0 m-0">
          <div class="row mt-2">
            <div class="col-lg-8 img mb-5">
              <img src="../assets/img/graham2x.png" alt="" />
            </div>
            <div class="col-lg-4 pr-5 pl-0 mb-5">
              <div class="h-100 w-100 bg-danger"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <img class="d-block d-lg-none" />
    <img class="d-none d-lg-block w-100" :src="require('@/assets/img/cover/cover.png')" />

  </div>

</template>

<script>
import HeaderOrder from '../components/HeaderOrder'
import TitleTextButton from '../components/TitleTextButton'
import Etapes from '../components/Etapes'
import identifier from '../assets/img/svg/identifier.svg'
import acculturer from '../assets/img/svg/acculturer.svg'
import codevelopper from '../assets/img/svg/codevelopper.svg'

export default {
  components: {
    HeaderOrder,
    TitleTextButton,
    Etapes
  },
  data () {
    return {
      etapes: [
        {
          circle: identifier,
          title: 'Identifier',
          content: "Elle identifie, analyse et suit les tendances et les innovations des start-ups, afin de déterminer la pertinence d'une collaboration avec elles et le meilleur moment pour l'opérer."
        },
        {
          circle: acculturer,
          title: 'Acculturer',
          content: 'Elle sensibilise et acculture les décideurs et les équipes internes aux nouveaux modèles et aux bouleversements du secteur.'
        },
        {
          circle: codevelopper,
          title: 'Codévelopper',
          content: 'Elle facilite le codéveloppement des start-ups à fort potentiel avec les entreprises pour permettre des coopérations plus équilibrées et plus rapides.'
        }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
img{
    display: block;
    margin: 0 auto;
}
</style>
